<template>
  <ul>
    <li>
      <icon v-tooltip:left="'Sent'" glyph="tick-box" />
      {{ numberFilter(stats['Promotion:sent']) }}
    </li>
    <li>
      <icon v-tooltip:left="'Failed'" glyph="cross-circle" />
      {{ numberFilter(stats['Promotion:failed']) }}
    </li>
  </ul>
</template>
<script>
import { numberFilter } from '@/helpers/filters'

export default {
  props: ['stats'],

  data() {
    return {
      numberFilter
    }
  }
}
</script>
