<template>
  <div>
    <label>{{ $t('delayToolboxSmallSpecificTimeLabel') }}</label>
    <div ref="timePicker" class="input-group date">
      <input type="text" class="form-control" :disabled="readOnly" />
      <span class="input-group-addon">
        <icon glyph="clock" />
      </span>
      <span class="input-group-addon">
        {{ getTimeZone }}
      </span>
    </div>

    <div class="checkbox">
      <label>
        <input
          ref="followingDay"
          type="checkbox"
          :checked="action.options.followingDay"
          :disabled="readOnly"
          @change="saveOptions"
        />
        {{ $t('delayToolboxSmallSpecificTimeFollowingDay') }}
      </label>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
import '@/libs/bootstrap-datepicker/bootstrap-datetimepicker'

export default {
  inject: ['workflowApi'],
  props: ['action', 'readOnly'],

  computed: {
    getTimeZone() {
      return this.action.options.timezone || this.$store.state.app.project.projectTimezone
    }
  },

  watch: {
    'action.options.time': 'updateDateTimePicker'
  },

  mounted() {
    $(this.$refs.timePicker)
      .datetimepicker({
        format: 'HH:mm',
        allowInputToggle: true,
        widgetPositioning: {
          horizontal: 'left',
          vertical: 'bottom'
        }
      })
      .on('dp.hide', this.saveOptions)

    this.timePicker = $(this.$refs.timePicker).data('DateTimePicker')

    this.updateDateTimePicker()
  },

  beforeDestroy() {
    this.timePicker.destroy()
    $(this.$refs.timePicker).off()
  },

  methods: {
    updateDateTimePicker() {
      this.preventSave = true

      if (this.action.options.time) {
        this.timePicker.date(this.action.options.time)
      }

      this.preventSave = false
    },

    getTime() {
      const date = this.timePicker.date()
      if (!date) {
        return ''
      }

      return date.format('HH:mm')
    },

    saveOptions() {
      if (this.preventSave) return

      const options = {
        type: 'specificTime',
        time: this.getTime(),
        timezone: this.getTimeZone,
        followingDay: this.$refs['followingDay'].checked
      }

      if (JSON.stringify(options) === JSON.stringify(this.action.options))
        return

      this.workflowApi.updateAction(this.action.id, {
        options
      })
    }
  }
}
</script>
